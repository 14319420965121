@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

/* Variables */
:root {
  --color-primary: #38ae90;
  --color-dark: #277a66;
  --color-font: #3b4753;
  --color-light: #f7f6f3;
}

/* Tag selectors */
body {
  font-family: "Roboto", sans-serif;
  color: var(--color-font);
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Class selectors */

/* Utilities */
.icon-lg {
  font-size: 1.4em;
}

.icon-xl {
  font-size: 1.8em;
}

.link {
  color: var(--color-light);
}

.btn {
  border: none;
  outline: none;
  padding: 0.9em 1.6em;
  background-color: var(--color-light);
  color: var(--color-font);
  font-weight: bold;
  border-radius: 25px;
  text-decoration: none;
}

.btn.btn-lg {
  padding: 0.9em 5em;
}

.btn:hover {
  background-color: var(--color-font);
  color: var(--color-light);
  cursor: pointer;
}

.d-flex {
  display: flex;
  justify-content: center;
}

/* Components */

/*--------------Header---------------*/

.header {
  background-color: var(--color-primary);
  color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1.1em;
}

.menu {
  position: fixed;
  background: var(--color-font);
  z-index: 3;
  top: 0;
  left: 0;
  width: 100vw;
  left: -100vw;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.menu ul li {
  font-size: 1.9em;
  text-align: center;
  margin-bottom: 20px;
}

.menu.is-active {
  left: 0;
}

.menu-link a {
  cursor: pointer;
  color: var(--color-light);
  text-decoration: none;
  transition: 0.3s;
}

.menu-link:hover {
  transform: scale(1.1);
}

.menu-icon-container {
  padding: 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #277a66ff;
  text-align: center;
  position: fixed;
  z-index: 4;
  left: 15px;
  top: 12px;
  cursor: pointer;
}

.brand {
  width: 100%;
  text-align: center;
}

/*--------------Main---------------*/

/*--------------Hero---------------*/

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0.9em 0;
}

.hero__img-container {
  width: 150px;
  height: 150px;
}

.hero__img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.hero__greeting h1 {
  width: 60%;
  word-wrap: break-word;
  margin: 10px auto 20px;
}

.job {
  overflow: hidden;
  border-right: 0.15em solid var(--color-dark);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.1em;
  animation: typing 2s steps(29, end) 1s 1 normal both,
    animated-cursor 600ms steps(29, end) infinite;
}

/* Animations  */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 170px;
  }
}
@keyframes typing-lg {
  from {
    width: 0;
  }
  to {
    width: 190px;
  }
}
@keyframes typing-xl {
  from {
    width: 0;
  }
  to {
    width: 260px;
  }
}

@keyframes animated-cursor {
  from {
    border-right-color: var(--color-dark);
  }
  to {
    border-right-color: transparent;
  }
}

.social-media-container {
  margin-top: 20px;
}


/*--------------About me---------------*/

.about-me__img {
  width: 100%;
  height: 32.5vh;
  margin: 0;
}

.about-me__img img {
  max-width: 100%;
  object-fit: cover;
}

.about-me__description {
  background-color: var(--color-primary);
  color: var(--color-light);
  padding: 2.1em 1.4em;
  position: relative;
}

.about-me__description span {
  word-wrap: break-word;
  line-height: 1.6em;
}

.about-me__description p {
  text-align: center;
}

.about-me__description::before {
  content: " ";
  width: 100px;
  height: 50px;
  position: absolute;
  border-top-left-radius: 50px;
  box-sizing: border-box;
  border-top-right-radius: 50px;
  border-bottom: 0;
  top: -30px;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  background-color: var(--color-primary);
}

/*--------------Projects---------------*/

.projects {
  margin-top: 20px;
  padding: 1.2em 1.4em;
}

.projects h3 {
  text-align: center;
}

.project-list {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  gap: 30px;
  padding: 1.2em;
}

.project {
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.project-link {
  text-decoration: none;
  color: var(--color-font);
  transition: 0.5s;
}

.project:hover {
  transform: scale(1.1);
}

.project__img-container {
  width: 100%;
  position: relative;
}

.project__img-container img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.project__info {
  background-color: var(--color-light);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0.8em 1.4em;
}

.project-top {
  position: relative;
}

.wave {
  width: 100%;
  position: absolute;
  bottom: -10px;
}

.project__tech {
  margin-top: 10px;
}

.tech-list {
  display: flex;
}

.tech-list li {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: var(--color-light);
  margin-right: 15px;
}

.project__description {
  font-size: 0.8em;
}

/*--------------Contacts---------------*/

.contact {
  display: flex;
  flex-direction: column;
  padding: 2em 1.4em;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: var(--color-light);
}

.contact .btn:hover {
  background-color: var(--color-primary);
  color: var(--color-light);
}

.contact__container {
  background-color: var(--color-font);
  border-radius: 20px;
  padding: 0.9em;
  margin-top: 20px;
}

.contact__form {
  padding: 1em 1.1em;
}

.input {
  margin-bottom: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: var(--color-light);
  color: var(--color-font);
  padding: 0.6em 1em;
}

.form__inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/*--------------Footer---------------*/

.footer {
  background-color: var(--color-font);
  color: var(--color-light);
  padding: 0.8em 1.4em;
}

.footer__copyright {
  margin: 0;
  text-align: center;
}

.social-media.social-light li {
  background-color: var(--color-light);
}
.social-media.social-light li:hover {
  background-color: var(--color-dark);
}
.social-media.social-light li:hover .link {
  color: var(--color-light);
}

.link.light {
  color: var(--color-dark);
}

/*--------------Media Queries---------------*/

@media screen and (min-width: 424px) {
  .about-me__img {
    height: 42vh;
  }
}

@media screen and (min-width: 600px) {
  .about-me__img {
    height: 50vh;
  }
}

@media screen and (min-width: 767px) {
  h1 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.4em;
  }

  .about-me__img {
    height: 68vh;
  }

  .hero__greeting h1 {
    width: 100%;
  }

  .btn {
    font-size: 16px;
  }

  body {
    font-size: 18px;
  }

  .project-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .form__inputs {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .input {
    font-size: 1.1em;
  }

  .mr-2 {
    margin-right: 10px;
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    font-family: inherit;
  }

  .footer .social-media {
    margin-top: 20px;
    justify-content: center;
  }

  .social-media li {
    width: 50px;
    height: 50px;
  }
  .job {
    animation-name: typing-lg, animated-cursor;
  }
}

@media screen and (min-width: 900px) {
  .about-me {
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
  }

  .about-me__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .about-me__description {
    padding: 3em 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .about-me__description p {
    text-align: start;
  }

  .about-me__description .d-flex {
    justify-content: flex-start;
  }

  .about-me__description::before {
    height: 100px;
    width: 50px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 50px;
    border-right: 0;
    left: -45px;
    right: initial;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (min-width: 1023px) {
  .hero {
    font-size: 25px;
    padding: 2.9em;
  }
  .hero__img-container {
    width: 240px;
    height: 240px;
  }

  .job {
    animation-name: typing-xl, animated-cursor;
  }
  .project-list {
    grid-template-columns: repeat(4, 1fr);
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-icon-container {
    display: none;
  }

  .tech-list li {
    width: 40px;
    height: 40px;
  }

  .menu {
    position: initial;
    display: flex;
    align-items: center;
    font-size: 10px;
    background-color: transparent;
  }

  .menu ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .menu ul li {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .social-media li {
    font-size: 0.8em;
  }

  .footer .social-media li {
    width: 50px;
    height: 50px;
  }

  .contact h3 {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .footer .social-media {
    margin-top: 0;
  }
}
