.social-media {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}

.social-link {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-primary);
  margin-right: 15px;
}

.social-media li:hover {
  background-color: var(--color-dark);
}